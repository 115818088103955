@import '../Header/Header.scss';

.ventory-content {
  height: calc(100vh - 40px);
  background-color: #f5f6f7;
  overflow-x: hidden;
  overflow-y: visible;
}

@media screen and (min-width: 2561px) {
  .ventory-content {
    padding-left: 320px;
    padding-right: 320px;
  }
}
